<template>
  <div class="user">
    <div class="new_login">
      <img src="../../../../static/images/login.png" />
    </div>
    <div class="login_con">
      <h1 style="font-weight: 500">蓝微星空后台管理系统</h1>
      <div class="title_two">Hi~欢迎登录</div>
      <el-form>
        <el-form-item class="loginInput">
          <el-input placeholder="请输入用户名" v-model="form.mobile" prefix-icon="el-icon-user"/>
        </el-form-item>
        <el-form-item class="loginInput">
          <el-input placeholder="请输入密码" v-model="form.password" prefix-icon="el-icon-lock" :show-password="showFlag"></el-input>
          <div class="showFlag" @click="showFlag = !showFlag">
            <img src="../../../../static/images/jr-icon-close.png" v-if="showFlag">
            <img src="../../../../static/images/jr-icon-eye.png" v-else>
          </div>
        </el-form-item>
        <el-form-item>
        <!-- <el-input v-model="form.code" prefix-icon=" el-icon-key">
          <template slot="append">获取验证码</template>
        </el-input> -->
        </el-form-item>
        <el-button
          class="btn"
          @click="onSubmit"
          type="primary"
          style="width: 520px; border-radius: 4px; margin-top:80px;height:60px;"
          >登录</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/login'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        mobile: '', // 账号
        password: '', //密码
      },
      showFlag:true
    }
  },
  methods: {
    async onSubmit() {
      if(this.form.mobile.length == 0 || this.form.password.length == 0) {
        this.$message.error('请输入密码和账号')
        return false
      }
      const res = await login(this.form)
      if (res.data.resultCode === 200) {
        this.$message.success('登录成功')
        const token = res.headers.token
        sessionStorage.setItem('token', token)
        sessionStorage.setItem('userId', res.data.data.id)
        sessionStorage.setItem('roleId', res.data.data.roleId)
        sessionStorage.setItem('userName', res.data.data.userName)
        sessionStorage.setItem('image', res.data.data.userAvatarKey)
        sessionStorage.setItem('mobile', res.data.data.mobile)
        sessionStorage.setItem('menuTree', JSON.stringify(res.data.data.actions))
        this.$router.replace({
          path: '/'
        })
      } else {
        this.$messageError(res.data.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  height: 60px;
  background: #f4f4f4;
  border-color:#f4f4f4;
  font-size: 18px;
  text-indent: 0.5em;
  color: #AAAAAA;
}
/deep/.el-icon-user{
  margin-top: 3px;
  font-size: 20px;
  color: #494949;
}
/deep/.el-icon-lock{
  margin-top: 3px;
  font-size: 20px;
  color: #494949;
}
/deep/.el-input__inner:focus{
  background: #7F54D41A;
  border-color: #7F54D41A;
  color: #333333;
  &::placeholder {
  color: #333;
}
}
.loginInput{
  margin-bottom:30px;
  width: 520px;
  border-radius: 4px;
  position: relative;
  /deep/.el-input__suffix{
    display: none;
  }
}
.showFlag{
  position: absolute;
  right: 10px;
  top: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  img{
    width: 100%;
    height: 100%;
  }
}
.user{
  width: 100%;
  margin: 0px auto;
  display: flex;
  .new_login{
    width: 50%;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .login_con{
    margin:300px 0 0 210px;
    h1 {
      font-size: 36px;
    }
    .title_two{
      font-size: 26px;
      margin: 10px 0 60px 0;
      color: #ccc;
    }
  }
  .icon-jr-icon-close{
    color: #ccc;
  }
  .btn{
    background: #7C71E1;
    color: #fff;
  }
}
</style>
